import { jsx as _jsx } from "react/jsx-runtime";
import { PureComponent } from 'react';
import Alert from '@mui/material/Alert';
import { DsRemixIcon } from '../DsRemixIcon';
const CloseIcon = (props) => (_jsx(DsRemixIcon, { ...props, fontSize: "mild", className: "ri-close-line" }));
export class DsToast extends PureComponent {
    render() {
        const { forwardedRef, ...restProps } = this.props;
        return (_jsx(Alert, { ref: forwardedRef, ...restProps, components: {
                ...this.props.components,
                CloseIcon
            } }));
    }
}
