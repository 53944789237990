import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { DsRemixIcon } from '../DsRemixIcon';
export const DsAutocompleteDefaultProps = {
    popupIcon: _jsx(DsRemixIcon, { className: "ri-arrow-down-s-line" }),
    clearIcon: _jsx(DsRemixIcon, { color: "secondary", className: "ri-close-line" }),
    multiple: false,
    disableClearable: false,
    freeSolo: false,
    renderInput: () => _jsx(_Fragment, {}),
    options: []
};
