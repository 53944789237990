import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import {
  contractNoteClearActions,
  getContractNoteCommoditiesActions,
  getContractNoteEquitiesActions
} from './Actions'
import { _orderBy } from '~/src/Utils/lodash'

const INITIAL_STATE = {
  contractNoteEquities: [],
  contractNoteCommodities: []
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},

  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getContractNoteEquitiesActions.success,
      (state, { payload }) => {
        // state.report = reduceData(payload)
        state.contractNoteEquities = _orderBy(
          payload,
          ['contractNoteDate'],
          'desc'
        )
      }
    )

    builder.addCase(
      getContractNoteCommoditiesActions.success,
      (state, { payload }) => {
        // state.report = reduceData(payload)
        state.contractNoteCommodities = _orderBy(
          payload,
          ['contractNoteDate'],
          'desc'
        )
      }
    )

    builder.addCase(contractNoteClearActions, state => {
      state.contractNoteEquities = []
      state.contractNoteCommodities = []
    })
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
