import { PALETTE } from '../../Constants';
import getLightModeColorScheme from './light';
import getDarkModeColorScheme from './dark';
import getHighContrastModeColorScheme from './highContrast';
export default function getColorScheme(colorPalette = PALETTE) {
    const mergedColorPalette = { ...PALETTE, ...colorPalette };
    const { lightDsColor, lightPalette } = getLightModeColorScheme(mergedColorPalette);
    const { darkDsColor, darkPalette } = getDarkModeColorScheme(mergedColorPalette);
    const { highContrastDsColor, highContrastPalette } = getHighContrastModeColorScheme(mergedColorPalette);
    const colorScheme = {
        light: {
            palette: lightPalette,
            ds: {
                colour: lightDsColor
            }
        },
        dark: {
            palette: darkPalette,
            ds: {
                colour: darkDsColor
            }
        },
        highContrast: {
            palette: highContrastPalette,
            ds: {
                colour: highContrastDsColor
            }
        }
    };
    return colorScheme;
}
