export const DsAccordionDetailsOverrides = {
    MuiAccordionDetails: {
        styleOverrides: {
            root: {
                border: 'none',
                borderBottom: '1px solid var(--ds-colour-strokeDefault)',
                padding: 'var(--ds-spacing-bitterCold)',
                fontWeight: 'var(--ds-typo-bodyRegularSmall-fontWeight)',
                fontSize: 'var(--ds-typo-bodyRegularSmall-fontSize)',
                lineHeight: 'var(--ds-typo-bodyRegularSmall-lineHeight)',
                letterSpacing: 'var(--ds-typo-bodyRegularSmall-letterSpacing)'
            }
        }
    }
};
