import { experimental_extendTheme as extendTheme, responsiveFontSizes } from '@mui/material/styles';
import getColorScheme from './getColorScheme';
import getTypography from './getTypography';
import breakpoints from './breakpoints';
import componentOverrides from './componentOverrides';
import dsRules from './rules';
import dsSpacing, { dsSpacingCssVars, SPACE_COEFFICIENT } from './spacing';
import dsElevation from './elevation';
import { PALETTE, FONT_FAMILY_NAME } from '../Constants';
import { DSTYPOGRAPHY_TOKENS } from '../Constants';
import dsRadius from './radius';
export function getTheme(palette = PALETTE, fontFamilyName = FONT_FAMILY_NAME) {
    const { typography, dsTypo } = getTypography(fontFamilyName);
    const colorSchemes = getColorScheme(palette);
    const colorSchemesKeys = Object.keys(colorSchemes);
    colorSchemesKeys.forEach((colorSchemesKey) => {
        colorSchemes[colorSchemesKey].ds = {
            ...colorSchemes[colorSchemesKey].ds,
            spacing: dsSpacingCssVars,
            typo: dsTypo,
            rules: dsRules,
            radius: dsRadius,
            elevation: dsElevation
        };
    });
    const cssVarsThemeOptions = {
        cssVarPrefix: '',
        components: componentOverrides,
        colorSchemes,
        shape: { borderRadius: 2 },
        breakpoints,
        typography,
        spacing: (input) => input * SPACE_COEFFICIENT
    };
    let theme = extendTheme(cssVarsThemeOptions);
    theme = responsiveFontSizes(theme, {
        disableAlign: true,
        breakpoints: breakpoints.keys,
        factor: 2,
        variants: DSTYPOGRAPHY_TOKENS
    });
    return theme;
}
export { dsSpacing, SPACE_COEFFICIENT };
