import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { SnackbarProvider, closeSnackbar, enqueueSnackbar, useSnackbar } from 'notistack';
import { DsNotistackAlertDefault, DsNotistackAlertSuccess, DsNotistackAlertError, DsNotistackAlertWarning, DsNotistackAlertInfo } from './AlertMessage.Component';
const useNotistack = useSnackbar;
export { closeNotistack, useNotistack, enqueueNotistack, generateKeyNotistack };
export class DsNotistackProvider extends Component {
    static defaultProps = {
        hideIconVariant: true
    };
    render() {
        return (_jsx(SnackbarProvider, { preventDuplicate: true, anchorOrigin: { vertical: 'top', horizontal: 'center' }, ...this.props, Components: {
                default: DsNotistackAlertDefault,
                success: DsNotistackAlertSuccess,
                error: DsNotistackAlertError,
                warning: DsNotistackAlertWarning,
                info: DsNotistackAlertInfo,
                ...this.props.Components
            } }));
    }
}
function generateKeyNotistack(message) {
    const key = `${message || ''}-${new Date().getTime()}`;
    return key;
}
function enqueueNotistack(notistackOptions) {
    const key = `${notistackOptions.message}-${new Date().getTime()}`;
    // Handle if key has been passed
    const notificationObj = { key, ...notistackOptions };
    enqueueSnackbar(notificationObj);
}
function closeNotistack(key) {
    closeSnackbar(key);
}
