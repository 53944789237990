import { createAction } from '@reduxjs/toolkit'

export const setErrorServiceName = 'error/setError'
export const setErrorAction = createAction<number, string>(
  `${setErrorServiceName}/SET_ERROR`
)

export const clearErrorServiceName = 'error/clearError'
export const clearErrorAction = createAction<undefined, string>(
  `${clearErrorServiceName}/CLEAR_ERROR`
)
