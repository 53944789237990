import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import {
  ssoCompleteActions,
  ssoInitiateActions,
  ssoValidateActions,
  updateCustomerDetailsAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import localforage from 'localforage'
import { loginWithRefreshTokenTraceActions } from '../Auth/Actions'

export interface validationResponse {
  sessionId: string
  refreshToken: { token: string; expireAt: string }
  authToken: { token: string; expireAt: string }
  metadata?: any
}

export interface authenticationResponse {
  refreshToken: { token: string; expireAt: string }
  authToken: { token: string; expireAt: string }
  metadata?: any
}

export interface initiateResponse {
  redirectURL: string
}

export interface completeResponse {
  redirectURL: string
}

export interface SsoStore {
  loading: boolean
  isValidated: boolean
  isAuthenticated: boolean
  SSOID: string
  customerName: string
  ssoValidation: validationResponse
  ssoAuthentication: authenticationResponse
  ssoInitiate: initiateResponse
  ssoComplete: completeResponse
  error: any
}

const INITIAL_STATE: SsoStore = {
  loading: false,
  isValidated: false,
  isAuthenticated: false,
  SSOID: '',
  customerName: '',
  ssoValidation: {
    sessionId: '',
    refreshToken: { token: '', expireAt: '' },
    authToken: { token: '', expireAt: '' },
    metadata: null
  },
  ssoAuthentication: {
    refreshToken: { token: '', expireAt: '' },
    authToken: { token: '', expireAt: '' },
    metadata: null
  },
  ssoInitiate: {
    redirectURL: ''
  },
  ssoComplete: {
    redirectURL: ''
  },
  error: { code: 0, message: '', isDispatch: false }
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    storeSsoId: (state, { payload }) => {
      state.SSOID = payload.ssoId
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    // updateAuthToken
    builder.addCase(
      loginWithRefreshTokenTraceActions.success,
      (state, { payload }) => {
        state.ssoAuthentication.authToken = payload.data
      }
    )

    // validate
    builder.addCase(ssoValidateActions.success, (state, { payload }) => {
      state.ssoValidation = payload
      state.isValidated = true
    })

    // complete
    builder.addCase(ssoCompleteActions.success, (state, { payload }) => {
      state.ssoComplete = payload
    })

    // initiate
    builder.addCase(ssoInitiateActions.success, (state, { payload }) => {
      state.ssoInitiate = payload
    })
  }
}

const ssoSlice = createSlice(sliceOptions)

export const { storeSsoId } = ssoSlice.actions

export const ssoPersistConfig = {
  key: SLICE_NAME,
  version: 1,
  storage: localforage,
  whitelist: ['customerName']
}

export default ssoSlice.reducer
