import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'

import {
  getCustomerProfileDetailsActions,
  getCustomerProfileDetailsServiceName
} from '~/src/Redux/Customer/Actions'
import { getRequiredDataForHeaders } from '~/src/Utils/global'

async function getCustomerProfileDetails() {
  const options = {
    url: '/customer-profile/details',
    method: 'GET',
    headers: getRequiredDataForHeaders()
  }
  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  if (!data) {
    let data: any = {}
    data.error = body.error

    return data
  }
  return data
}

const getCustomerProfileDetailsAction = serviceActionCreator(
  getCustomerProfileDetailsActions,
  getCustomerProfileDetails
)

export default getCustomerProfileDetailsAction
export { getCustomerProfileDetailsServiceName }
