import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from 'react';
import { closeSnackbar } from 'notistack';
import { DsToast } from '../DsToast';
const AlertMessageDefaultProps = {
    toastVariant: 'filled',
    message: '',
    onClose: props => undefined
};
class AlertMessage extends Component {
    static defaultProps = AlertMessageDefaultProps;
    handleClose = () => {
        const { id, onClose } = this.props;
        if (onClose && typeof onClose === 'function') {
            onClose(this.props);
        }
        closeSnackbar(id);
    };
    render() {
        const { forwardedRef, message, toastVariant, variant, icon, action, sx } = this.props;
        return (_jsx(DsToast, { forwardedRef: forwardedRef, variant: toastVariant, color: variant, sx: sx, icon: icon, action: action, onClose: this.handleClose, children: message }));
    }
}
export const DsNotistackAlertDefault = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "default" });
});
export const DsNotistackAlertSuccess = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "success" });
});
export const DsNotistackAlertError = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "error" });
});
export const DsNotistackAlertWarning = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "warning" });
});
export const DsNotistackAlertInfo = React.forwardRef((props, ref) => {
    return _jsx(AlertMessage, { forwardedRef: ref, ...props, variant: "info" });
});
