import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { withBreakpoints } from '../../Hocs/withBreakpoints';
import { DsDialog } from '../DsDialog';
import { DsBottomSheet } from '../DsBottomSheet';
class Popup extends React.Component {
    render() {
        const { breakpoints, DsBottomSheetProps, DsDialogProps, ...PopupProps } = this.props;
        if (breakpoints.xs || breakpoints.sm) {
            return (_jsx(DsBottomSheet, { ...PopupProps, ...DsBottomSheetProps, children: PopupProps?.children || DsBottomSheetProps?.children }));
        }
        return (_jsx(DsDialog, { ...PopupProps, ...DsDialogProps, children: PopupProps?.children || DsDialogProps?.children }));
    }
}
export const DsPopup = withBreakpoints(Popup);
